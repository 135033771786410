// Assets
import HemsonLogoRed from "../../assets/Hemson-Logo.png";

/**
 * Hemson logo.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {number} [props.width] - Width of the logo in pixels [Optional].
 * @param {number} [props.height] - Height of the logo in pixels [Optional].
 * @param {('eager'|'lazy')} [props.loading] - The loading prop "eager", "lazy" or undefined [Optional]. Default is undefined.
 * @returns The Hemson logo.
 */
const HemsonLogo = ({ 
  width,
  height,
  loading 
}) => {
  return (
    <>
      <img 
        className="img-fluid" 
        src={HemsonLogoRed}
        alt='Hemson logo' 
        width={width}
        height={height}
        loading={loading}
      />
    </>
  );
}

export default HemsonLogo;