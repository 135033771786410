// Components
import Card from 'react-bootstrap/Card';

/**
 * Card component containing an icon on the top left with negative margin.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {} props.icon - The icon to use. Must be an svg.
 * @param {} props.title - A title for the card.
 * @param {} props.text - The body text.
 * @returns A feature card.
 */
const FeatureCard = ({
  icon,
  title = 'Placeholder Title',
  text = 'Placeholder Text',
}) => {
  return (
    <Card className='bg-dark text-light'>
      <Card.Body>
        <div className="d-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3" style={{width: '4rem', height: '4rem', borderRadius: '0.75rem', marginTop: '-2.5rem'}}>
          {icon}       
        </div>
        <Card.Title>{title}</Card.Title>
        <Card.Text>
          {text}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default FeatureCard;