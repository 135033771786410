import { useEffect, useRef, useState } from 'react';

/**
 * A span element with a number that counts from 0 to maxValue over time milliseconds.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {number} props.time - Time until the count reaches maxValue default = 2000.
 * @param {number} props.maxValue - The maxValue to reach default = 1000.
 * @returns A span element.
 */
const ScrollCounter = ({
  time = 2000,
  maxValue = 1000
}) => {
  
  const counterRef = useRef(null);
  const [count, setCount] = useState(0);
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting && !hasStarted) {
              setHasStarted(true);
              startCounter();
              observer.unobserve(counterRef.current);
            }
        });
    }, { threshold: 1.0 });

    observer.observe(counterRef.current);

    return () => observer.disconnect();
  }, [hasStarted]);

  /**
   * Keeps track and updates the counter based on time and maxValue.
   */
  const startCounter = () => {
    const duration = time; // milliseconds
    const target = maxValue; // max value
    const startTime = performance.now();

    const updateCounter = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      setCount(Math.floor(progress * target));

      if (progress < 1) {
        requestAnimationFrame(updateCounter);
      }
    };

    requestAnimationFrame(updateCounter);
  };

  return (
    <span ref={counterRef}>{count}</span>
  );
};

export default ScrollCounter;