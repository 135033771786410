/**
 * Icon of an arrowhead.
 * @component
 * @returns Icon of and arrowhead.
 */
const ArrowHeadIcon = ({
  flipHorizontal = false,
  className = ''
}) => {

  const flip = flipHorizontal ? 'rotateY(180deg)' : '';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1422 300" height={300} className={`${className}`} style={{transform: flip}}>
      <g strokeWidth="10" stroke="hsl(227, 71%, 57%)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="8.5 22" transform="matrix(1,0,0,1,30,-260)">
        <path d="M200 300.5Q301.2816581726074 591.5 711.7816581726074 400Q1106.2816581726074 206.5 1092.2816581726074 550 " markerEnd="url(#SvgjsMarker3216)"></path>
      </g>
      <defs>
        <marker markerWidth="6" markerHeight="6" refX="3" refY="3" viewBox="0 0 6 6" orient="auto" id="SvgjsMarker3216">
          <polyline points="0,3 3,1.5 0,0" fill="none" strokeWidth="1" stroke="hsl(227, 71%, 57%)" strokeLinecap="round" transform="matrix(1,0,0,1,1,1.5)" strokeLinejoin="round"></polyline>
        </marker>
      </defs>
    </svg>
  );
}

export default ArrowHeadIcon;