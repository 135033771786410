// Components
import Nav from 'react-bootstrap/Nav';

/**
 * Navigation link.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {string} props.href - A url.
 * @param {React.JSX.Element} props.icon - A boostrap icon.
 * @param {string} props.text - A text label for the link.
 * @param {boolean} props.external - Boolean indicating if it is an external link. Default is false.
 * @param {string} props.className - className prop.
 * @returns A navigation link with animated icon.
 */
const NavigationLink = ({
  href,
  icon,
  text,
  external = false,
  className = ''
}) => {
  return (
    <Nav.Link className={`icon-link icon-link-hover fw-semibold hemson-secondary ${className}`} href={href} target={external ? '_blank' : ''} rel={external ? 'noopener noreferrer' : ''}>
      {icon}
      {text}
    </Nav.Link>
  );
}

export default NavigationLink;