// React
import { useState, useEffect } from 'react';
// Components
import Container from 'react-bootstrap/Container';
// Assets
import Toronto from "../../assets/toronto-skyline.webp";
import Erin from '../../assets/erin-skyline.webp';
import Woodstock from '../../assets/woodstock-skyline-2400.webp';
import Hamilton from '../../assets/hamilton-skyline-2400.webp';
import ThunderBay from '../../assets/thunderbay-skyline-2400.webp';
import Residential from '../../assets/residential-development.webp';

/**
 * The parallax div with image background.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {React.JSX.Element} [props.children] - Child element or component that goes inside a Container element [Optional].
 * @param {string} [props.className] - className prop. [Optional].
 * @param {string} [props.height] - Height of the parallax div in pixels [Optional].
 * @returns A parallax div.
 */
const ParallaxDiv = ({
  children,
  className = '',
  height = '50'
}) => {

  const backgroundImages = [
    Toronto,
    Erin,
    Woodstock,
    Hamilton,
    ThunderBay,
    Residential
  ];

  const [currentBackground, setCurrentBackground] = useState('');

  useEffect(() => {
    // Set a random background when the component mounts
    setRandomBackground();
  }); // Empty dependency array ensures it runs only once when mounted

  const setRandomBackground = () => {
    const randomIndex = Math.floor(Math.random() * backgroundImages.length);
    const selectedBackground = backgroundImages[randomIndex];
    
    // Log the selected background to the console for debugging
    // console.log('Selected Background:', selectedBackground);

    setCurrentBackground(selectedBackground);
  };

  return (
    <div className='overflow-hidden inside-bottom-shadow'
      style={{
        position: 'relative',
        minHeight: `${height}vh`,
        backgroundImage: `url(${currentBackground})`,
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      {/* Overlay div */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        }}
      >
      </div>
      <Container className={`${className}`} style={{ position: 'relative', zIndex: 1, minHeight: `${height}vh` }}>
        {children}
      </Container>
    </div>
  );
};

export default ParallaxDiv;