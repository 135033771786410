/**
 * Icon of a straight vertical arrowhead.
 * @component
 * @returns Icon of a straight vertical arrowhead.
 */
const ArrowHeadVerticalIcon = ({
  className = ''
}) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 800 800" height={300} className={`${className}`}>
      <g strokeWidth="13" stroke="hsl(227, 71%, 57%)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="16 28" transform="rotate(45, 400, 400)">
        <line x1="200" y1="200" x2="600" y2="600" markerEnd="url(#SvgjsMarker1321)"></line>
      </g>
      <defs>
        <marker markerWidth="6" markerHeight="6" refX="3" refY="3" viewBox="0 0 6 6" orient="auto" id="SvgjsMarker1321">
          <polyline points="0,3 3,1.5 0,0" fill="none" strokeWidth="1" stroke="hsl(227, 71%, 57%)" strokeLinecap="round" transform="matrix(1,0,0,1,1,1.5)" strokeLinejoin="round"></polyline>
        </marker>
      </defs>
    </svg>
  );
}

export default ArrowHeadVerticalIcon;