// React
import { useState, useEffect } from 'react';
import { analytics, logEvent, isLocal } from './services/firebase.config';
// Bootstrap and Styles
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
// Components
import { 
  Fade, 
  Bounce, 
  Slide, 
  Zoom, 
  AttentionSeeker 
} from "react-awesome-reveal";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ReactTyped } from "react-typed";
import { 
  PatchCheckFillIcon,
  ChatRightTextFillIcon,
  EnvelopeFillIcon,
  LinkedInIcon,
  TwitterIcon,
  GraphUpIcon,
  PersonArmsUpIcon,
  PCDisplayIcon,
  PinMapIcon,
  HouseIcon,
  BarChart
} from './components/Icons/Icons';
import ParallaxDiv from './components/Containers/ParallaxDiv';
import HemsonLogo from './components/Icons/HemsonLogo';
import ArrowHeadIcon from './components/Icons/ArrowHead';
import ArrowHeadVertical from './components/Icons/ArrowHeadVertical'
import BirdsFlyingIcon from './components/Icons/Birds';
import ScrollCounter from './components/Animation/ScrollCounter';
import FeatureCard from './components/Cards/FeatureCard';
import LoginButton from './components/Nav/LoginButton';
import NavigationLink from './components/Nav/NavigationLink';
// Assets
import DashboardLight1000x700 from "./assets/dashboard-light-1000x700.webp";
import DashboardDark1000x700 from "./assets/dashboard-dark-1000x700.webp";
import HemsonAnalyticsLogo from "./assets/Hemson-Analytics-Logo.webp";
import Ontario350x450 from "./assets/ontario-350x450.webp";
import ResidentialConstruction350x450 from "./assets/residential-construction-350x450.webp";
import Residential300x300 from "./assets/residential-300x300.webp";
import City300x300 from "./assets/city-300x300.webp";
import Rural300x300 from "./assets/rural-300x300.webp";
import DashboardLight3D from "./assets/dashboard-light-3d.webp";
import DashboardDark3D from "./assets/dashboard-dark-3d.webp";
import DashboardLight300x300 from "./assets/dashboard-light-300x300.webp";
import DashboardDark300x300 from "./assets/dashboard-dark-300x300.webp";

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

/**
 * Logs a button click on the Contact Hemson button to Google Analytics.
 */
const logContactButtonClick = () => {

  if (isLocal) {
    return;
  }

  logEvent(analytics, 'button_click', { // button_click is an arbitrary name for an event
    button_name: 'contact_button', // Any set of parameters can be defined here to help document the event
  });
};


/* -------------------------------------------------------------------------- */
/*                                   App.js                                   */
/* -------------------------------------------------------------------------- */

const App = () => {

  const [bgClass, setBgClass] = useState('bg-none');
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const storageTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light';
  const [theme, setTheme] = useState(storageTheme);

  /**
   * Handles the background color of the navbar with different window scroll positions.
   */
  useEffect(() => {
    const handleScroll = () => {

      if (navbarExpanded && window.scrollY === 0) {
        setBgClass('bg-primary-subtle');
        return;
      }

      const show = window.scrollY > 0;
      setBgClass(show ? 'bg-primary-subtle' : 'bg-none');
    };

    // Add the event listener when component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [navbarExpanded]);
  
  /**
   * Handles theme when theme state changes.
   */
  useEffect(() => {
    setTheme(theme);

    const htmlElement = document.documentElement; // The <Html> element
    htmlElement.setAttribute('data-bs-theme', theme);

    localStorage.setItem('theme', theme);
  }, [theme]);

  /**
   * Controls the background color of the navbar on small screen.
   * @param {boolean} expanded - Boolean indicating if navbar is expanded. 
   */
  const navbarBackgroundToggle = (expanded) => {
    let background = 'bg-none';
    const scrolled = window.scrollY > 0;
    
    if (expanded) {
      background = 'bg-primary-subtle';
    } else {
      background = scrolled ? 'bg-primary-subtle' : 'bg-none';
    } 

    setBgClass(background);
    setNavbarExpanded(expanded);
  }

  /**
   * Function that handles theme setting.
   */
  const handleThemeChange = (e) => {

    const checked = e.currentTarget.checked;
    const htmlElement = document.documentElement; // The <Html> element

    if (checked) {
      htmlElement.setAttribute('data-bs-theme', 'dark');
      setTheme('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      htmlElement.setAttribute('data-bs-theme', 'light');
      setTheme('light');
      localStorage.setItem('theme', 'light');
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                      HEMSON ANALYTICS LANDING PAGE                         */
  /* -------------------------------------------------------------------------- */

  return (

    /* -------------------------------------------------------------------------- */
    /*                                   NAVBAR                                   */
    /* -------------------------------------------------------------------------- */

    <div>
      <Navbar collapseOnSelect expand="lg" className={bgClass} fixed='top' onToggle={(expanded) => navbarBackgroundToggle(expanded)}>
        <Container>
          
          <Navbar.Brand href="#home">
            <div className='position-relative'>
              <img
                alt="Hemson Analytics logo"
                src={HemsonAnalyticsLogo}
                width={100}
                height={48}
                className="d-inline-block align-top"
                fetchpriority='high'
              />
            </div>
          </Navbar.Brand>
          
          <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav variant='underline' className='me-auto'>              
              <NavigationLink href={'#features'} icon={<GraphUpIcon/>} text={'Features'}/>
              <NavigationLink href={'#about'} icon={<ChatRightTextFillIcon/>} text={'About'}/>
              <NavigationLink href={'#contact'} icon={<EnvelopeFillIcon/>} text={'Contact'}/>

            </Nav>
            <Nav.Item className='mt-3 mt-lg-0'>
              <Form.Switch
                defaultChecked={theme === 'dark'}
                onChange={e => handleThemeChange(e)}
                label={theme === 'dark' ? '🌙' : '☀️'}
                id='theme-checkbox'
                title='theme-checkbox'
              />              
            </Nav.Item>
            <Nav variant='underline' className='mt-3 mt-lg-0'>
              <LoginButton/>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* -------------------------------------------------------------------------- */
       /*                                   HEADER                                   */
       /* -------------------------------------------------------------------------- */}
      
      <ParallaxDiv height='100'>
        <Row className="vh-100 align-items-center justify-content-center" id='home'>
          <Col xs={12} className='mb-auto py-5 d-lg-none'></Col>
          <Col xs={12} lg={6} className='hemson-secondary'>
            
            <Fade duration={2000} triggerOnce>
              <Zoom delay={900} duration={1000} triggerOnce>
                <div>
                  <h1 className="display-1">Welcome to</h1>
                  <h1 className="display-1">Hemson Analytics</h1>
                  <div className='d-flex'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" className="bi bi-unlock me-2" viewBox="0 0 16 16">
                      <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2M3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1z"/>
                    </svg>
                    <p className="display-6 mb-0">Unlock municipal</p>
                  </div>
                  <p className="display-6">
                    finance{' '} 
                    <span className="fw-medium">
                      <ReactTyped
                        strings={[
                          'data', 
                          'benchmarks', 
                          'insights'
                        ]}
                        typeSpeed={150}
                        backSpeed={50}
                        loop
                      />
                    </span>
                  </p>              
                  <div className="d-flex align-items-center my-4">
                    <p className="fs-4 mb-0 me-2">Developed by</p>
                    <a href="https://www.hemson.com/" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center mb-1">
                      <HemsonLogo width={150} height={62}/>
                    </a>
                  </div>
              
                  <Button href="#features" className="icon-link icon-link-hover fw-semibold" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-door-open" viewBox="0 0 16 16">
                      <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"/>
                      <path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z"/>
                    </svg>
                    Explore the Features
                  </Button>
                </div>
              </Zoom>
            </Fade>
                      
          </Col>
          
          <Col xs={12} lg={6} className="position-relative my-3 my-lg-0">

            <div className='position-absolute top-0 end-0' style={{zIndex: '-1000'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width={700} version="1.1" viewBox="0 0 600 600" style={{marginTop: '-150px', marginRight: '-150px', opacity: '0.25'}}>
                <path d="M538.18896484375,57.861351013183594C468.42956924438477,-4.7130889892578125,148.63368733723956,77.97922643025716,78.8742904663086,150.35133361816406C9.114893595377609,222.72344080607098,49.87318801879883,429.5195541381836,119.63258361816406,492.093994140625C189.3919792175293,554.6684341430664,427.67126719156903,598.1700808207194,497.4306640625,525.7979736328125C567.190060933431,453.4258664449056,607.9483604431152,120.435791015625,538.18896484375,57.861351013183594C468.42956924438477,-4.7130889892578125,148.63368733723956,77.97922643025716,78.8742904663086,150.35133361816406" fill="url(&quot;#SvgjsLinearGradient1000&quot;)" fillOpacity="1" transform="matrix(1.0645333193009812,0,0,1.0645333193009812,-19.910570041913388,-19.333577758115666)" strokeWidth="0" stroke="hsl(340, 45%, 30%)"></path><defs><linearGradient id="SvgjsLinearGradient1000" gradientTransform="rotate(0, 0.5, 0.5)"><stop stopColor="hsl(44, 53%, 37%)" offset="0"></stop><stop stopColor="hsl(44, 53%, 57%)" offset="1"></stop></linearGradient></defs>
              </svg>
            </div>

            {/* Holds the large screen 3d image */}            
            <Fade duration={2000} triggerOnce className='d-none d-lg-block'>
              <Slide direction='right' triggerOnce>
                <Row>
                  <Col>
                    <img src={theme === 'dark' ? DashboardDark3D : DashboardLight3D} className='img-fluid' alt="Hemson Analytics dashboard" width={650} height={498}/>
                  </Col>
                </Row>
              </Slide>
            </Fade>

            {/* Shows only on small screen */}            
            <Fade duration={2000} triggerOnce className='d-lg-none'>
              <Slide direction='right' triggerOnce>
                <Row>
                  <Col>
                    <div className='d-flex justify-content-center '>
                      <img src={theme === 'dark' ? DashboardDark300x300 : DashboardLight300x300} className="rounded img-fluid dashboard-feature-margin-bottom" alt="Hemson Analytics dahsboard" width={300} height={300}/>
                    </div>
                  </Col>
                </Row>
              </Slide>
            </Fade>
                
          </Col>
        
        </Row>
      </ParallaxDiv>   
      
      {/* -------------------------------------------------------------------------- */
       /*                                  FEATURES                                  */
       /* -------------------------------------------------------------------------- */}
      
      <section>
        <Container>
          <Row className='g-4 align-items-xl-center'>
        
              <Col lg={7}>
                <Row className="pe-xl-5">
                  <Col sm={6} className='d-none d-sm-block'>
        
                    <img src={ResidentialConstruction350x450} className="rounded img-fluid" alt="Residential construction" width={350} height={450}/>
        
                    <figure className="text-end me-3" style={{marginTop: '-2em'}}>
                      <BirdsFlyingIcon/>
                    </figure>
                  </Col>
                  <Col sm={6}>
        
                    <Row className="mb-4">
                      <Col sm={6} className="mb-4 mb-sm-0">
                        <div className="bg-success text-white rounded text-center p-3">
                          <span className="h2 text-white"><ScrollCounter maxValue={1000} time={2000}/>+</span>
                          <p className="mb-0">Data points</p>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="bg-primary rounded text-center p-3">
                          <span className="h2 text-white"><ScrollCounter maxValue={400} time={2000}/>+</span>
                          <p className="mb-0 text-white">Municipalities</p>
                        </div>
                      </Col>
                    </Row>
        
        
                    <img src={Ontario350x450} className="rounded img-fluid" alt="Map of Ontario on a keyboard" width={350} height={450}/>
                  </Col>
                </Row>
              </Col>
        
        
              <Col lg={5}>
                <a href='#contact' className="bg-primary-subtle rounded px-3 py-2 link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover">🤝 Get access today!</a>
                <h2 className="my-4">Defining the future of municipal finance{' '}<br className='d-xxl-none'/>
                    <span className='fw-bold text-success'>
                      <ReactTyped
                        strings={[
                          'benchmarks',
                          'data',
                          'insights'
                        ]}
                        typeSpeed={150}
                        backSpeed={50}
                        loop
                      />
                    </span>
                </h2>
                <p className="mb-4">Embrace a new era of municipal finance benchmarks with Hemson Analytics. Hemson combines 40+ years of experience working with municipalities to bring curated financial data to users all through a user friendly dashboard.</p>
        
                <ul className="list-group list-group-borderless mb-4">
                  <li className="list-group-item heading-color mb-0"><PatchCheckFillIcon className='me-2 text-success' />Easy to use</li>                  
                  <li className="list-group-item heading-color mb-0"><PatchCheckFillIcon className='me-2 text-success' />Reliable data</li>
                  <li className="list-group-item heading-color mb-0"><PatchCheckFillIcon className='me-2 text-success' />Built by municipal finance experts</li>
                </ul>
        
                <Button href="#dashboardSchema" className="icon-link icon-link-hover fw-semibold" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-door-open" viewBox="0 0 16 16">
                    <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"/>
                    <path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z"/>
                  </svg>
                  Explore the Dashboard
                </Button>
              </Col>
        
          </Row>
        </Container>
      </section>

      {/* -------------------------------------------------------------------------- */
       /*                              DASHBOARD SCHEMA                              */
       /* -------------------------------------------------------------------------- */}
    
      <span className="anchor" id='features'></span>
      <Container fluid className='bg-body-secondary'>
        <section id='dashboardSchema'>
          <Container>
            <Row className='mb-5'>
              <Col className='text-center'>
                <h1 className='fw-bold'>A dashboard developed with municipalities in mind</h1>
                <p className='h3'>Check out some of the features!</p>
              </Col>
            </Row>
            
            <Row className='g-5 h-100 align-items-center position-relative hemson-secondary'>

              <Col xs={12} lg={4}>
                <Zoom delay={100} triggerOnce fraction={1}>
                  <FeatureCard
                    title='Valuable Insights'
                    text='View comparative benchmarks on expenditures, taxation, assessment, service levels, utility rates, staffing and much more.'
                    icon={<PCDisplayIcon/>}
                  />
                </Zoom>
              </Col>

              <Col xs={12} lg={4} xxl={{ span: 4, offset: 4 }}>
                <Zoom delay={100} triggerOnce fraction={1}>
                  <FeatureCard
                    title='User Focused'
                    text="An innovative design makes viewing benchmarks easy and quick. Filter data and view through intuitive charts and graphics."
                    icon={<PersonArmsUpIcon/>}
                  />
                </Zoom>
              </Col>
              
              {/* Set of 2 Responsive Divs */}              
              <Col xs={12} md={12} lg={4} className='d-xxl-none'>
                <Zoom delay={100} triggerOnce fraction={1}>
                  <FeatureCard
                    title='Municipal Profile'
                    text='Get key financial and demographic data for any municipality. Hemson Analytics aggregates commonly requested data instantly.'
                    icon={<HouseIcon/>}
                  />
                </Zoom>
              </Col>

              <div className="position-absolute top-50 start-0 translate-middle z-3 d-none d-xxl-block" style={{width: '25%'}}>
                <Zoom delay={100} triggerOnce fraction={1}>
                  <FeatureCard
                    title='Municipal Profile'
                    text='Get key financial and demographic data for any municipality. Hemson Analytics aggregates commonly requested data instantly.'
                    icon={<HouseIcon/>}
                  />
                </Zoom>
              </div>

              {/* Dashboard Image */}
              <Col xs={12} md={12} className='position-relative'>
                <Row className='d-flex justify-content-center'>
                  <Col xs={12} className='inside-bottom-shadow overflow-hidden d-flex justify-content-center'>
                    <Slide direction='up' triggerOnce fraction={1}>
                      <img src={theme === 'dark' ? DashboardDark1000x700 : DashboardLight1000x700} className='img-fluid border rounded shadow dashboard-feature-margin-bottom' alt='Hemson Analytics dashboard' loading='lazy' width={1000} height={700}/>
                    </Slide>
                  </Col>
                </Row>
              </Col>

              {/* Set of 2 Responsive Divs */}
              <div className="position-absolute top-50 start-100 translate-middle z-3 d-none d-xxl-block" style={{width: '25%'}}>
                <Zoom delay={100} triggerOnce fraction={1}>
                  <FeatureCard
                    title='Municipal Comparators'
                    text='Compare benchmarks across municipalities, with data standardized and processed for "apple-to-apple" comparisons.'
                    icon={<BarChart/>}
                  />
                </Zoom>              
              </div>

              <Col xs={12} md={12} lg={4} className='d-xxl-none'>
                <Zoom delay={100} triggerOnce fraction={1}>
                  <FeatureCard
                    title='Municipal Comparators'
                    text='Compare benchmarks across municipalities, with data standardized and processed for "apple-to-apple" comparisons.'
                    icon={<BarChart/>}
                  />
                </Zoom>
              </Col>

              <Col xs={12} lg={4}>
                <Zoom delay={100} triggerOnce fraction={1}>
                  <FeatureCard
                    title='Over 400+ Municipalities'
                    text="Data made available from Ontario's FIR and Hemson's proprietary data, with thousands of data points to choose from."
                    icon={<PinMapIcon/>}
                  />
                </Zoom>
              </Col>

              <Col xs={12} lg={4} xxl={{ span: 4, offset: 4 }}>
                <Zoom delay={100} triggerOnce fraction={1}>
                  <FeatureCard
                    title='Dashboard Tools'
                    text='Built in tools to help users navigate the data. Want to revisit a set of benchmarks? Save using presets to come back to your chosen municipalities.'
                    icon={<GraphUpIcon/>}
                  />
                </Zoom>
              </Col>
            
            </Row>
          </Container>
        </section>
      </Container>
      

      {/* -------------------------------------------------------------------------- */
       /*                                    ABOUT                                   */
       /* -------------------------------------------------------------------------- */}
      
      <span className="anchor" id='about'></span>
      <section>
        <Container className='overflow-hidden'>
          <Row className='mb-5'>
            <Col className='text-center'>
              <h1 className='fw-bold'>Hemson Analytics is modernizing municipal finance</h1>
              <p className='h3'>What began as a tool for consultants is now available to everyone</p>
            </Col>
          </Row>
          
          <Row xs={1} lg={2}>            
            <Col className='d-flex align-items-center'>
              <Row>
                <Col className='py-2'>
                  <Fade duration={2000} triggerOnce>
                    <Slide cascade damping={0.1} direction='left' triggerOnce>
                      <p className="fw-bold h3">Municipal financial data reimagined</p>
                      <p className="lead">
                        Hemson recognizes how important access to finance benchmarks are to municipalities. They provide insights on the fiscal health and performance of municipalities to help decision makers optimize how services are provided. Hemson Analytics was created for this reason, to facilitate access of financial benchmarks to municipalities and stakeholders.
                      </p>
                    </Slide>
                  </Fade>
                </Col>
              </Row>
            </Col>
            <Col className='d-flex justify-content-center py-2'>
              <Fade duration={2000} triggerOnce>
                <Slide direction='right' triggerOnce>
                  <div className='position-relative'>
                    <div className="d-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 position-absolute top-0 end-0" style={{width: '4rem', height: '4rem', borderBottomLeftRadius: '0.75rem', borderTopRightRadius: '0.375rem'}}>
                      <PCDisplayIcon/>       
                    </div>
                    <img src={Rural300x300} className='rounded shadow img-fluid' alt='A rural municipality' loading='lazy' width={300} height={300}/>
                  </div>
                </Slide>
              </Fade>
            </Col>
          </Row>

          <Row className='py-5'> 
            <Col lg={12} className='d-flex justify-content-center position-relative'>
              <div className='position-absolute top-50 start-50 translate-middle'>
              <Bounce delay={500} triggerOnce fraction={1}>
                <div className='bg-light rounded-circle'>                  
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-check-circle-fill text-success" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                  </svg>                  
                </div>
                </Bounce>
              </div>
              
              <ArrowHeadIcon className='d-none d-lg-block'/>
              <ArrowHeadVertical className='d-lg-none'/>
            </Col>
          </Row>

          <Row xs={1} lg={2} className='flex-wrap-reverse'> 
            <Col className='d-flex justify-content-center py-2'>
              <Fade duration={2000} triggerOnce>
                <Slide direction='left' triggerOnce>
                  <div className='position-relative'>
                    <div className="d-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 position-absolute top-0 end-0" style={{width: '4rem', height: '4rem', borderBottomLeftRadius: '0.75rem', borderTopRightRadius: '0.375rem'}}>
                      <BarChart/>       
                    </div>
                    <img src={City300x300} className='rounded shadow img-fluid' alt='The skyline of a city' loading='lazy' width={300} height={300}/>
                  </div>
                </Slide>
              </Fade>
            </Col>            
            <Col className='d-flex align-items-center py-2'>
              <Row>
                <Col>
                  <Fade duration={2000} triggerOnce>
                    <Slide cascade damping={0.1} direction='right' triggerOnce>
                      <h3 className="fw-bold">A wealth of data</h3>
                      <p className="lead">
                        Hemson Analytics utilizes the Financial Information Return to develop financial insights on Ontario's municipalities. This data is further enhanced through Hemson's proprietery datasets to provide a complete financial picture.
                      </p>
                    </Slide>
                  </Fade>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className='py-5'> 
            <Col lg={12} className='d-flex justify-content-center position-relative'>
              <ArrowHeadIcon flipHorizontal={true} className='d-none d-lg-block'/>
              <ArrowHeadVertical className='d-lg-none'/>
              <div className='position-absolute top-50 start-50 translate-middle'>                
                <Bounce delay={500} triggerOnce fraction={1}>
                  <div className="bg-light rounded-circle">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-check-circle-fill text-success" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                  </div>
                </Bounce>
              </div>              
            </Col>
          </Row>

          <Row xs={1} lg={2}> 
            <Col className='d-flex align-items-center py-2'>
              <Row>
                <Col>
                  <Fade duration={2000} triggerOnce>
                    <Slide cascade damping={0.1} direction='left' triggerOnce>
                      <h3 className="fw-bold">Developed by municipal finance professionals</h3>
                      <p className="lead">
                        Hemson, with over 40 years of experience in municipal finance, has developed Hemson Analytics to help users access hundreds of benchmarks with just a few clicks. Our innovative dashboard is simple to use and aggregates the most relevant data instantly.
                      </p>
                    </Slide>
                  </Fade>
                </Col>
              </Row>
            </Col>
            <Col className='d-flex justify-content-center py-2'>
              <Fade duration={2000} triggerOnce>
                <Slide direction='right' triggerOnce>
                  <div className='position-relative'>
                    <div className="d-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 position-absolute top-0 end-0" style={{width: '4rem', height: '4rem', borderBottomLeftRadius: '0.75rem', borderTopRightRadius: '0.375rem'}}>
                      <GraphUpIcon/>       
                    </div>
                    <img src={Residential300x300} className='rounded shadow img-fluid' alt='Residential neighborhood' loading='lazy' width={300} height={300}/>
                  </div>
                </Slide>
              </Fade>
            </Col>        
          </Row>

        </Container>
      </section>            

      {/* -------------------------------------------------------------------------- */
       /*                                   CONTACT                                  */
       /* -------------------------------------------------------------------------- */}

      <span className="anchor" id='contact'></span>
      <ParallaxDiv className='d-flex align-items-center'>        
        <Row className='justify-content-center text-light my-5'>
          <Col lg={5} className='text-center position-relative'>

            <h1 className='mb-5'>Get access to Hemson Analytics today!</h1>
            <p className='lead mb-5'>We offer competitive pricing options for municipalities, businesses and individuals. Reach out to Hemson to discuss our available options and get access today.</p>
            
            <AttentionSeeker effect='bounce'>
              <Button as='a' href="https://www.hemson.com/contact-us/" className="icon-link icon-link-hover fw-semibold" target="_blank" rel="noopener noreferrer" onClick={logContactButtonClick}>
                <EnvelopeFillIcon/>
                Contact Hemson
              </Button>
            </AttentionSeeker>
          
          </Col>
        </Row>
      </ParallaxDiv>

      {/* -------------------------------------------------------------------------- */
       /*                                   FOOTER                                   */
       /* -------------------------------------------------------------------------- */}
      
      <Container fluid className='bg-primary-subtle pt-1'>
        <Container className='mt-5'>
          <Row className='g-3'>
            <Col xs={true} lg={3}>
              <Row className="row-cols-1 g-4">
                <p className='h5 hemson-secondary'>Navigation</p>
                <Col>
                  <NavigationLink href={'#features'} icon={<GraphUpIcon/>}  text={'Features'}/>
                </Col>
                <Col>
                  <NavigationLink href={'#about'} icon={<ChatRightTextFillIcon/>} text={'About'}/>
                </Col>
                <Col>
                  <NavigationLink href={'#contact'} icon={<EnvelopeFillIcon/>} text={'Contact'}/>
                </Col>
      
                <Col>
                  <LoginButton/>
                </Col>
      
              </Row>
            </Col>
            <Col xs={true} lg={3}>
              <Row className="row-cols-1 g-4">
                <p className='h5 hemson-secondary'>Social Media</p>
      
                <Col>
                  <NavigationLink href={'https://ca.linkedin.com/company/hemson-consulting-ltd-'} icon={<LinkedInIcon/>} text={'LinkedIn'} external={true}/>
                </Col>
                <Col>
                  <NavigationLink href={'https://x.com/hemsonconsult?lang=en'} icon={<TwitterIcon/>} text={'Twitter'} external={true}/>
                </Col>
              </Row>
            </Col>
            <div className="w-100"></div>
            <Col lg={6} className='position-relative'>
              <p className='h5'>Developed by:</p>
              <a href="https://www.hemson.com/" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center mb-3">
                <HemsonLogo width={150} height={62} loading='lazy'/>                                                    
              </a>
              <p>Hemson Analytics is Ontario's new municipal benchmarking web application developed by the municipal finance experts at Hemson Consulting. Since 1983, Hemson Consulting has provided expert advice in the areas of planning policy, municipal finance, demographic and economic forecasting, land needs assessment, real estate advisory and transportation impact analysis.</p>
              
              <p><small>&copy; Hemson Consulting 2024</small></p>

            </Col>
          </Row>
        </Container>
      </Container>

    </div>
  );
}

export default App;
