import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// create-react-app auto generated ('development' or 'production')
const isLocal = process.env.NODE_ENV === "development";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDSIwvLcVsaSd7WIj1HkfEh8BZ_k6p0biQ",
  authDomain: "citydata-46dc9.firebaseapp.com",
  projectId: "citydata-46dc9",
  storageBucket: "citydata-46dc9.appspot.com",
  messagingSenderId: "348439327532",
  appId: "1:348439327532:web:67beae4785016616987289",
  measurementId: "G-LTLV853TRJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = isLocal ? '' : getAnalytics(app);

export {
  analytics,
  logEvent,
  isLocal
};