// Components
import Nav from 'react-bootstrap/Nav';
// Assets
import { ArrowInRightIcon } from '../Icons/Icons';

/**
 * The navigation link that directs the user to app.hemsonanalytics.com.
 * @component
 * @returns 
 */
const LoginButton = () => {

  return (
    <Nav.Link 
      href='https://app.hemsonanalytics.com/' 
      className='icon-link icon-link-hover fw-semibold hemson-secondary'
    >
      <ArrowInRightIcon/>
      Sign In
    </Nav.Link>
  );
}

export default LoginButton;